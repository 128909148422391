<template>
  <div class="fields">
    <v-row v-for="(field, rowIndex) of rows" :key="`row-${rowIndex}`">
      <v-col>
        <v-card>
          <v-card-text class="card-box">
            <v-row>
              <v-col class="pa-0 pr-3 d-inline-flex justify-space-between" style="cursor: pointer" @click="editField(field)">
                <slot name="label" :field="field">
                  <span>
                    <b>{{ field }}</b>
                  </span>
                </slot>
                <div>
                  <v-btn v-if="ordering" :disabled="rowIndex === 0" class="ma-0" text x-small icon @click.stop="moveField('up', field)">
                    <v-icon small> keyboard_arrow_up </v-icon>
                  </v-btn>
                  <v-btn v-if="ordering" :disabled="rowIndex === rows.length - 1" class="ma-0" text x-small icon @click.stop="moveField('down', field)">
                    <v-icon small> keyboard_arrow_down </v-icon>
                  </v-btn>
                  <v-btn class="ma-0" text x-small icon :disabled="true" @click.stop="removeField(field)">
                    <v-icon small> close </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <slot name="editor" :selectedField="selectedField" :editing="editing" :closeHandler="closeHandler" :saveHandler="saveHandler" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  name: 'SortableFieldsList',
  props: {
    value: Array,
    removeCallback: Function,
    ordering: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    lazyFields: [],
    selectedField: {},
    editing: false
  }),
  computed: {
    internalFields: {
      get() {
        return this.lazyFields
      },
      set(value) {
        this.lazyFields = value
        this.$emit('input', value)
      }
    },
    rows() {
      return this.internalFields.map((field, index) => ({ ...field, index }))
    }
  },
  watch: {
    value(value) {
      this.lazyFields = this.value
    }
  },
  mounted() {
    this.lazyFields = this.value
  },
  methods: {
    closeHandler() {
      this.editing = false
    },
    saveHandler(field) {
      const fields = cloneDeep(this.internalFields)
      fields[field.index] = field
      this.internalFields = fields

      this.editing = false
      this.selectedField = field
    },
    editField(field) {
      this.selectedField = field
      this.editing = true
    },
    async removeField(field) {
      const confirmed = await this.$confirm({
        title: 'Remover loja',
        message: 'Deseja remover esta loja?',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmed) {
        return
      }

      if (this.removeCallback) {
        await this.removeCallback(field)
      }

      const fields = cloneDeep(this.internalFields)
      fields.splice(field.index, 1)
      this.internalFields = fields
    },
    moveField(direction, field) {
      const fields = cloneDeep(this.internalFields)
      if (direction === 'up') {
        fields[field.index] = fields[field.index - 1]
        fields[field.index - 1] = field
      } else if (direction === 'down') {
        fields[field.index] = fields[field.index + 1]
        fields[field.index + 1] = field
      }
      this.internalFields = fields
    }
  }
}
</script>

<style lang="scss">
.fields {
  .card-box {
    padding: 3px 5px 5px 30px;
    p {
      margin: 0;
      width: 220px;
      text-align: left;
      font-size: 12px;
      margin: 0 !important;
    }
  }
  .drag-icon {
    position: absolute;
    left: 5px;
    top: 6px;
    color: #d6dbe0;
  }
}
</style>
